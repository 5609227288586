import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material'
import { deepPurple, pink } from '@mui/material/colors'

import './App.css'

import About from './pages/about/About'
import Landing from './pages/landing/Landing'
import ROUTES from './routes'
import NotFound from './pages/not-found/NotFound'

const router = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <Landing />
  },
  {
    path: ROUTES.about,
    element: <About />
  },
  {
    path: ROUTES.notFound,
    element: <NotFound />
  }
])

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontSize: 12
  },

  palette: {
    primary: deepPurple,
    secondary: pink
  }
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <div className="app-background">
          <RouterProvider
            router={router}
            fallbackElement={<div>loading</div>}
            future={{ v7_startTransition: true }}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
