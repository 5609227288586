import React, { useCallback } from 'react'

import './NotFound.css'

import Page from '../../components/Page'
import TEXTS from '../../utils/text'
import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../../routes'

const NotFound = () => {
  const navigate = useNavigate()

  const handleBackClick = useCallback(() => {
    navigate(ROUTES.home, { replace: true })
  }, [])

  return (
    <Page>
      <div className="not-found">
        <div className="not-found__title">
          <h1>{TEXTS.notFound.title}</h1>
        </div>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="not-found__section__text">
            <p>{TEXTS.notFound.message}</p>
          </div>
          <Button id="not-found-back-btn" onClick={handleBackClick}>
            {TEXTS.notFound.callToAction}
          </Button>
        </Stack>
      </div>
    </Page>
  )
}

export default NotFound
