import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import './Menu.css'

import { ScreenSize, useScreenSize } from '../../../hooks/screen-size'
import { Instagram } from '../../SocialMedia'
import ROUTES from '../../../routes'

const Menu = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useScreenSize(screenSize, setScreenSize)

  return (
    <>
      <div className="header__menu">
        <NavLink
          id="navlink-home"
          to={ROUTES.home}
          className={({ isActive }) =>
            isActive ? 'header__menu-item--active clicable ' : 'header__menu-item clicable'
          }
        >
          Home
        </NavLink>
        {/* <a className="header__menu-item clicable" href="?" onClick={onClickHomeHandler}>
          Contact
        </a> */}
        <NavLink
          id="navlink-about"
          to={ROUTES.about}
          className={({ isActive }) =>
            isActive ? 'header__menu-item--active clicable ' : 'header__menu-item clicable'
          }
        >
          About
        </NavLink>
      </div>
      <div className="header__rrss">
        <Instagram id="icon-header-ig" />
      </div>
    </>
  )
}

export default Menu
