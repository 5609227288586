import React from 'react'

import './Page.css'

import Header from '../Header'
import Footer from '../Footer'

type PageProps = {
  children: React.ReactNode
}

const Page = ({ children }: PageProps) => {
  return (
    <>
      <Header />
      <div className="page-content">{children}</div>
      <Footer />
    </>
  )
}

export default Page
